<template>
    <div>
        <event-header
          :showBackButton="true">
        </event-header>

        <fieldset class="fieldset">
          <legend>Choix du terrain pour cette épreuve</legend>
          <b-form-radio-group
              v-model="fieldForEvent"
buttons
              button-variant="inline-secondary"
          >
            <field-editor
              v-for="field in fields"
              :key="field"
              :showSummary="true"
              :value="field"
              :size="sm"
              :canSetHotState="isFieldSelected(field)"
              :showSelector="true"
              :isSelected="isFieldSelected(field)"
              @selection_change="fieldSelection_change"
              @hotState_change="fieldHotState_change"
            ></field-editor>
          </b-form-radio-group>
        </fieldset>

        <fieldset class="fieldset">
          <legend>Sélection du staff pour l'épreuve</legend>
          <div class="actors-list-container">
              <detailed-staff-list
                  :canSelectRole="true"
                  :items="staffUsages"
                  :showShortname="true"
                  @roleChanged="roleChanged"
              >
              </detailed-staff-list>
          </div>
        </fieldset>

        <fieldset class="fieldset" v-if="false">
          <legend>Règles de lissage des notes</legend>
            <b-checkbox switch :disbaled="!canSetSkipWorseNoteRule" v-model="skipWorseNote">Activer la règle d'élimination de la plus mauvaise note.</b-checkbox>
            <b-checkbox switch :disabled="!canSetSkipBestNoteRule" v-model="skipBestNote">Activer la règle d'élimination de la meilleure note.</b-checkbox>
        </fieldset>

        <fieldset class="fieldset">
          <legend>Rappel des compétiteurs engagés</legend>
          <detailed-competitors-list
            :items= "competitors"
            :canAdd="false"
            :canSelect="false"
            :canEdit="false"
            :canRemove="false"
            :canGiveUp="this.isTestMode"
            :canChangePresence="this.isTestMode"
            :showName="true"
            :showOrder="true"
            @changePresence = "changeCompetitorPresence"
            @giveUp = "competitorGiveUp"
            @unGiveUp = "competitorUngiveUp"
          >
          </detailed-competitors-list>
        </fieldset>

        <div v-if="!currentEvent.isStarted">
            <b-button class="btn-event" variant="primary" :class="{ 'inactif': !isLaunchable }" :disabled="!isLaunchable" @click="gotoEvent">Commencer l'épreuve</b-button>
        </div>
        <div v-if="currentEvent.isStarted">
            <b-modal
              title="Relancer l'épreuve"
              v-model = "confirmRestart"
              ok-variant="danger"
              ok-title="Oui"
              @ok = "razEvent"
              cancel-variant="secondary"
              cancel-title="Non"
            >
                <div class="text">
                    <div class="dialogTitle">Etes-vous certain de vouloir écraser tous les scores de cette épreuve et la relancer depuis le début ?</div>
                    <div class="dialogRemark">En choisissant "oui" les scores actuels seront immérédiablement effacés et tous les compétiteurs devront repasser.</div>
                </div>
            </b-modal>

            <div class="text">
                <div class="dialogTitle">L'épreuve est commencée !</div>
                <div>Voulez-vous la reprendre ?</div>
            </div>
            <b-button class="btn-event" variant="success" :to="{name:'EventRun'}">Continuer l'épreuve</b-button>
            <div class="text">
                <div class="dialogTitle">Ou la relancer depuis le début ?</div>
                <div class="warning"><strong>Attention</strong>Tous les scores déjà enregistrés pour tous les compétieurs déjà passés seront écrasés et tous les compétiteurs devront recommencer l'épreuve</div>
            </div>
            <b-button class="btn" variant="danger" @click="confirmRestart=true">Relancer l'épreuve depuis le début</b-button>
        </div>
        <loading-overlay v-model="isLoading" />
    </div>
</template>

<script>
import { RST_CATEGORY, RST_COMPETITION_STAFF, RST_EVENT, RST_FIELD } from "@/LocalDB";

import { v4 as uuid } from "uuid";
import DetailedStaffList from '@/components/DetailedStaffList';
import EventHeader from "@/components/EventHeader";
import ModelViewsHelper from "@/services/store/models/ModelViews";
import EventsConfigurationHelper from "@/services/helpers/eventsConfigurationHelper";
import DetailedCompetitorsList from '@/components/DetailedCompetitorsList.vue';
import { CompetitorExecOrderRuleEnum, EventExecutionStepEnum } from '@/Constants';
import { CompetitorsNotesHelper } from "@/services/helpers/competitorsNotesHelper";
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import FieldEditor from '@/components/fieldEditor.vue';
import SynchroHelper from "@/services/helpers/synchroHelper"

export default {
  components: { DetailedStaffList, EventHeader, DetailedCompetitorsList, LoadingOverlay, FieldEditor },
    data(){
        return {
            staffUsages: [],
            isLaunchable: false,
            confirmRestart: false,
            skipWorseNote: true,
            skipBestNote: false,
            canSetSkipWorseNoteRule: false,
            canSetSkipBestNoteRule: false,
            isLoading: false,
        };
    },
    computed: {
        canSetSkipNotesRules(){
            return this.canSetSkipWorseNoteRule || this.canSetSkipBestNoteRule;
        },
        competitors(){
          var ret = this.currentEvent.COMPETITORS.filter(item => !item.isForfeited && !item.isAbsent && !item.isWithdrawn );
          if(ret != null && ret.length > 0 && this.currentRound.competitors_order_rule != null && this.currentRound.competitors_order_rule != CompetitorExecOrderRuleEnum.Subscription )
           {
            switch(this.currentRound.competitors_order_rule)
            {
              case CompetitorExecOrderRuleEnum.Alea:
                ret.sort((a,b) => this.compare(a.randow_order, b.randow_order));
                break;
              case CompetitorExecOrderRuleEnum.Alpha:
                ret.sort((a,b) => this.compare(a.COMPETITOR.name, b.COMPETITOR.name, 'asc'));
                break;
              case CompetitorExecOrderRuleEnum.AlphaReverse:
                ret.sort((a,b) => this.compare(a.COMPETITOR.name, b.COMPETITOR.name, 'desc'));
                break;
              case CompetitorExecOrderRuleEnum.PrevRoundReverse:
                console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. PrevRoundReverse + "' à implémenter");
                break;
              case CompetitorExecOrderRuleEnum.Rank:
                console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. Rank + "' à implémenter");
                break;
              case CompetitorExecOrderRuleEnum.RankReverse:
                console.log ('tri des compétiteurs par ordre "' + CompetitorExecOrderRuleEnum. RankReverse + "' à implémenter");
                break;
            }
          }
          var o = 1;
          return ret.map(c => { c.order = o++; return c; });
        },
        fields(){
          return RST_FIELD.query().where("competition_id", this.currentCompetition_id).orderBy(f => f.field_number).get();
        }
    },
    methods:{
      isFieldSelected(field){
        return (this.currentEvent.field_number != null) && (this.currentEvent.field_number == field.field_number);
      },
        fieldSelection_change(e){
          var upd = e.isSelected ? { field_number: e.field.field_number} : { field_number: null};
          RST_EVENT.update({ where: this.currentEvent.id, value: upd});
          SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, upd, "SET_FIELD" );
          SynchroHelper.TryToSynchronize();
        },
        fieldHotState_change(fieldNumber, newValue){
            var field = RST_FIELD.query().where("competition_id", this.currentCompetition_id).where("field_number", fieldNumber).first();
            var data = { isHot: newValue};
            RST_FIELD.update({ where: field.id, data: data });
            SynchroHelper.LogOperation("UPDATE", "RST_FIELD", field.id, data, "CHANGE_HOT_STATE");
            SynchroHelper.TryToSynchronize();
        },
        refresh(){
          console.log('refresh');
            this.staffUsages = ModelViewsHelper
                                .getDetailedCompetitionStaffListItems(this.currentCompetition, this.currentEvent.STAFF_MEMBERS)
                                .sort((a,b) => { return (a.name < b.name) ? -1 : ((a.name > b.name) ? 1 : 0); });

            var errors = EventsConfigurationHelper.missingRequirementsBeforeRun(this.currentEvent);
            var judgesCount = this.currentEvent.JUDGES.filter(item => !item.isShadow).length;
            this.isLaunchable = (errors.length == 0);
            this.skipWorseNote = this.currentEvent.skipWorseNote;
            this.skipBestNote = this.currentEvent.skipBestNote;

            this.canSetSkipWorseNoteRule = (judgesCount >= this.currentEvent.CATEGORY.RULE.minJudgesRequiredToSkipWorseNote);
            this.canSetSkipBestNoteRule = (judgesCount >= this.currentEvent.CATEGORY.RULE.minJudgesRequiredToSkipBestNote);
        },
        roleChanged(e){
            this.selectForEvent(e.roleName, e.memberId);
        },
        selectForEvent(asFunction, staffMemberId)
        {
          this.$showRefresher()
            .then(() => {
               var member = null;
              var members = this.currentEvent.STAFF_MEMBERS.filter(item => item.staff_id == staffMemberId);
              if(members == null || members.length == 0)
              {
                var source = this.currentCompetition.STAFF_MEMBERS.filter(item => item.staff_id == staffMemberId)[0];
                var newData = {
                  id: this.newGuid(),
                  round_number: this.currentEvent.round_number,
                  level: this.currentEvent.level,
                  category: this.currentEvent.category,
                  event: this.currentEvent.event,
                  staff_id: source.staff_id,
                  competition_id: source.competition_id
                };
                RST_COMPETITION_STAFF.insert({ data: newData });
                SynchroHelper.LogOperation("INSERT", "RST_COMPETITION_STAFF", null, newData);
                member = newData;
              }
              else{
               member = members[0];
              }

              var change = {
                isShadow: (asFunction === 'judgeShadow' || asFunction === 'fieldDirectorShadow' || asFunction === 'scorerShadow') && !member.isShadow,
                isJudge: (asFunction === 'judge' || asFunction === 'judgeShadow') && !member.isJudge,
                isFieldDirector: (asFunction === 'fieldDirector' || asFunction === 'fieldDirectorShadow') && !member.isFieldDirector,
                isScorer: (asFunction === 'scorer' || asFunction === 'scorerShadow') && !member.isScorer,
                isTechJudge: (asFunction === 'techJudge' || asFunction === 'techJudgeShadow') && !member.isTechJudge,
                isArtJudge: (asFunction === 'artJudge' || asFunction === 'artJudgeShadow') && !member.isArtJudge
              };
              RST_COMPETITION_STAFF.update({ where: member.id, data : change });
              SynchroHelper.LogOperation("UPDATE", "RST_COMPETITION_STAFF", member.id, change, "EVENT_USAGE");
              SynchroHelper.TryToSynchronize();
              this.refresh();
              this.$hideRefresher();
          });
        },
        changeCompetitorPresence(competitor){
          this.$showRefresher()
            .then(() => {
              console.log("changeCompetitorPresence", competitor);
              this.$hideRefresher();
            });
        },
        competitorGiveUp(competitor){
          this.$showRefresher()
            .then(() => {
              console.log("ompetitorGiveUp", competitor);
              this.$hideRefresher();
            });
        },
        competitorUngiveUp(competitor){
          this.$showRefresher()
            .then(() => {
              console.log("competitorUngiveUp", competitor);
              this.$hideRefresher();
            });
        },
        razEvent(){
          this.$showRefresher()
            .then(()=>{
              EventsConfigurationHelper
                .razEvent(this.currentCompetition_id,
                          this.currentRound_number,
                          this.currentLevel_code,
                          this.currentCategory_code,
                          this.currentEvent_code);
                this.$hideRefresher();
            });
        },

        gotoEvent(){
          this.$showLoader()
            .then(() => {
                var dataToInitialize = {
                    current_competitor_id: this.competitors[0].competitor_id,
                    isCompleted: false,
                    isBriefingStarted: true
                };
                if(this.currentEvent.EVENT.hasCompulsories == true){
                  dataToInitialize.current_step = EventExecutionStepEnum.Compulsories;
                  dataToInitialize.current_compulsory_id = this.currentEvent.COMPULSORIES[0].compulsory_id;
                } else if (this.currentEvent.EVENT.hasRoutine == true) {
                  dataToInitialize.current_step = EventExecutionStepEnum.Routine;
                } else if (this.currentEvent.EVENT.hasBallet == true){
                  dataToInitialize.current_step = EventExecutionStepEnum.Ballet;
                }
                RST_EVENT.update({ where: this.currentEvent.id, data: dataToInitialize });
                SynchroHelper.LogOperation("UPDATE", "RST_EVENT", this.currentEvent.id, dataToInitialize, "INIT_EVENT" );


              // A déplacer dans la validation du briefing ?
              if(this.currentCategory == null)
              {
                var newCategory = { competition_id: this.currentCompetition_id,
                  round_number: this.currentRound_number,
                  level: this.currentLevel_code,
                  category: this.currentEvent.category,
                  id: uuid()
                };
                RST_CATEGORY.insert({ data: newCategory });
                SynchroHelper.LogOperation("INSERT", "RST_CATEGORY", null, newCategory);
              }


              CompetitorsNotesHelper.razEventNotes(this.currentCompetition_id, this.currentRound_number, this.currentLevel_code, this.currentCategory_code, this.currentEvent_code);
              SynchroHelper.TryToSynchronize();

              this.$router.push({ name: 'EventRun'});
          });
        },
    },
    mounted(){
      this.refresh();
      this.$hideLoader();
    }
}

</script>

<style scoped>
    .location.selective { font-weight:bolder; font-size:1.4rem; }
    .roundNo { font-weight: bolder; font-size: 1.2rem; }
    .level { font-weight: bolder;   }
    .category { font-weight: bolder;  }
    .format { font-weight: bolder; }
    .inactif { color: darkgray; cursor: default; }
    .infos-category{ border:black 1px solid; border-radius: 4px; margin:10px; padding:0.5rem;}
    .actors-list {
        width:85%;
        display:grid;
        grid-template-columns:0px, 4fr, repeat(5,70px);
    }
    .competitors-list{ flex-direction: column;}
    .actors-list > div { margin: 0; padding: 2px 5px;}
    .competitors { font-weight: bolder; text-decoration: blue 2px underline; margin-top:1rem;}
    .judges { font-weight:bolder; text-decoration: blue 2px underline; margin-top:1rem;}
    .fieldDirectors  { font-weight:bolder; text-decoration: blue 2px underline; margin-top:1rem;}
    .btn, .btn-event { margin-top: 10px; margin-bottom: 10px;}
    .text { margin-top:20px; margin-bottom:20px;}
    .dialogTitle{ font-weight: bold; }
    .warning{ color:orangered; }
    .btn-event.completed { border:green 1px solid; background-color:lightgreen;}
    .btn-event.toBeCompleted { border: darkorange 2px solid; background-color:lightsalmon; }
    .event-name { font-weight: bolder; }
    .btn-results { border: green 2px solid; background-color:greenyellow; color:green; font-weight: bolder; font-size: 1.5rem;}
    .header{ grid-row:1 }
    .judgeName{ grid-column: 2;  }
    .isJudge{ grid-column: 3;}
    .isJudgeShadow{ grid-column: 4;}
    .isFieldDirector{ grid-column: 5;}
    .isFieldDirectorShadow{ grid-column: 6; }
    .isScorer{ grid-column: 7; }
    .judgeName{ grid-column: 2; border: solid 1px lightgray; }

    .actors-list-container{ display: flex; justify-content: center; flex-wrap: wrap; margin-top: 15px; width:100% }
    .actors-list.header > div { border: none;}

        .header > .judgName{  vertical-align: bottom; }
        .header > .isJudge::after{ content: 'Juge'; text-align:center; vertical-align: bottom;}
        .header > .isJudgeShadow::after{ content: 'Juge ombre';text-align:center; vertical-align: bottom;}
        .header > .isFieldDirector::after{ content: 'Directeur de Terrain';text-align:center; vertical-align: bottom;}
        .header > .isFieldDirectorShadow::after{ content: 'Directeur de Terrain ombre';text-align:center; vertical-align: bottom;}
        .header > .isScorer::after{ content: 'Scoreur'; text-align:center; vertical-align: bottom;}

    .judgeName { min-width:7fr; max-width:7fr;  border:none; border-right: solid 1px lightgray; }
    .header { font-size: 0.8em; border:none; border-bottom: solid 1px lightgray; text-align: center;}

    input[type='checkbox']{ width:20px; height:20px;}

    @media screen and (max-width: 767px) and (orientation: portrait) {
        .actors-list {

            grid-template-columns: 0px, 6fr, repeat(5,70px);
        }
        .actors-list > div { margin: 0; padding: 4px 12px; text-align:center;}
        .actors-list > div.judgeName{ min-width:7fr; max-width:7fr; text-align:left;}

        .header > .isJudge::after{ content: 'J'; }
        .header > .isJudgeShadow::after{ content: 'Jo';}
        .header > .isFieldDirector::after{ content: 'DT';}
        .header > .isFieldDirectorShadow::after{ content: 'DTo';}
        .header > .isScorer::after{ content: 'S'; }
    }

</style>
