<template>
  <loading
    :active = "value"
    :color = "vColor"
    :backgroundColor = 'vBackgroundColor'
    :isFullPage = "true"
    :lockScroll = "true"
    :loader= 'vLoader'
    :canCancel = "canCancel"
  >
  </loading>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
  components: { Loading },
  props: {
    value: { type: Boolean, default:false},
    loader: { type: String},
    canCancel: { type: Boolean, default:false },
    variant: { type: String, default: 'loading'}
  },
  computed:{
    vColor(){
      return (this.variant == 'loading') ? /*'#f0850f'*/  '#589ed0' : '#589ed0';
    },
    vBackgroundColor(){
      return (this.variant == 'loading') ? "#c7c7c7" /*'#589ed0'*/ : '#f0850f';
    },
    vLoader(){
      return (this.loader != null && this.loader != '') ? this.loader : ((this.variant == 'loading') ? 'dots' : 'spinner');
    }

  }
}
</script>
