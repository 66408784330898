<template>
    <div>
        <b-button @click="add"  variant="primary" v-if="canAdd"><b-icon icon="person-plus-fill" />&nbsp;Ajouter</b-button>
        <b-table small hover :items="itemsList" :fields="columns" class="thisTable" responsive>
            <template #cell(selector)="data">
                <b-checkbox size="lg" switch v-model="data.item.isSelected" :disabled="data.item.cannotBeSelected" @change.native="select(data.item)"></b-checkbox>
            </template>
            <template #cell(edit)="data">
                <b-button @click="edit(data.item)" size="sm"><b-icon icon="pencil-fill"/></b-button>
            </template>

            <template #cell(name)="data">
                <span class="name firstname">{{ data.item.firstname }}</span> <span class="name lastname">{{ data.item.lastname }}</span>
            </template>
            <template #cell(firstname)="data">
                <span class="firstname">{{ data.item.firstname }}</span>
            </template>
            <template #cell(lastname)="data">
                <span class="lastname">{{ data.item.lastname }}</span>
            </template>
            <template #cell(shortname)="data">
                <span class="shortname">{{ data.item.shortname }}</span>
            </template>
            <template #cell(federalNumber)="data">
<!--                <span class="federalNumber" :class="{ 'inactive': !data.item.federalNumber.isActive, active: data.item.federalNumber.isActive, 'invvalid' : !data.item.federalNumber.isValid, 'valid': data.item.federalNumber.isValid}">
                    {{ data.item.federalNumber.number }}
                </span>
-->
                <span class="federalNumber" :class="{'invalid': data.item.federalNumber.theme=='danger', inactive: !data.item.federalNumber.isActive}">
                    {{ data.item.federalNumber.number }}
                </span>
                <b-badge v-if="data.item.isFederal && data.item.federalNumber.theme!=''" pill :variant="data.item.federalNumber.theme" @click="editLicensee(data.item)">!</b-badge>
            </template>
            <template #cell(chiefJudge) = "data">
              <b-checkbox size="sm" v-if = "!canSetChiefJudges && (data.item.isChiefJudge || data.item.canBeChiefJudge)" v-model = "data.item.isChiefJudge" :disabled = "true"></b-checkbox>
              <b-checkbox size="lg" switch v-if = "canSetChiefJudges && data.item.canBeChiefJudge" v-model = "data.item.isChiefJudge" @change.native="setAsChiefJudge(data.item)"></b-checkbox>
            </template>
            <template #cell(judge) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeJudge" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeJudge" v-model = "data.item.isJudge" @change.native="selectRole(data.item.id, 'judge')"></b-checkbox>
            </template>
            <template #cell(techJudge) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeTechJudge" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeTechJudge" v-model = "data.item.isTechJudge" @change.native="selectRole(data.item.id, 'techJudge')"></b-checkbox>
            </template>
            <template #cell(artJudge) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeArtJudge" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeArtJudge" v-model = "data.item.isArtJudge" @change.native="selectRole(data.item.id, 'artJudge')"></b-checkbox>
            </template>
            <template #cell(fieldDirector) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeFieldDirector" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeFieldDirector" v-model = "data.item.isFieldDirector" @change.native="selectRole(data.item.id, 'fieldDirector')"></b-checkbox>
            </template>
            <template #cell(judgeShadow) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeJudgeShadow" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeJudgeShadow" v-model = "data.item.isJudgeShadow" @change.native="selectRole(data.item.id, 'judgeShadow')"></b-checkbox>
            </template>
            <template #cell(techJudgeShadow) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeTechJudgeShadow" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeTechJudgeShadow" v-model = "data.item.isTechJudgeShadow" @change.native="selectRole(data.item.id, 'techJudgeShadow')"></b-checkbox>
            </template>
            <template #cell(artJudgeShadow) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeArtJudgeShadow" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeArtJudgeShadow" v-model = "data.item.isArtJudgeShadow" @change.native="selectRole(data.item.id, 'artJudgeShadow')"></b-checkbox>
            </template>
            <template #cell(fieldDirectorShadow) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBefieldDirectorShadow" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeFieldDirectorShadow" v-model = "data.item.isFieldDirectorShadow" @change.native="selectRole(data.item.id, 'fieldDirectorShadow')"></b-checkbox>
            </template>
            <template #cell(scorer) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeScorer" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeScorer" v-model = "data.item.isScorer"  @change.native="selectRole( data.item.id, 'scorer')"></b-checkbox>
            </template>
            <template #cell(scorerShadow) = "data">
                <b-checkbox size="sm" v-if = "!canSelectRole" v-model = "data.item.canBeScorerShadow" :disabled = "true"></b-checkbox>
                <b-checkbox size="lg" v-if = "canSelectRole && data.item.canBeScorerShadow" v-model = "data.item.isScorerShadow"  @change.native="selectRole( data.item.id, 'scorerShadow')"></b-checkbox>
            </template>
            <template #cell(remove)="data">
                <b-button variant="danger" @click="remove(data.item)" size="sm"><b-icon icon="trash-fill" /></b-button>
            </template>
        </b-table>
    </div>
</template>

<script>

export default {
    props:{
        canAdd: { type: Boolean, default:false},
        canRemove: { type: Boolean, default:false},
        canEdit: { type: Boolean, default:false},
        canSelect: { type: Boolean, default:false},
        canSelectRole: { type: Boolean, default:false},
        showRollnumber: { type: Boolean, default:false},
        showName: { type: Boolean, default: false},
        showFirstname: { type: Boolean, default: false},
        showLastname: { type: Boolean, default: false},
        showShortname: { type: Boolean, default: false},
        showTechAndArtForJudge: { type: Boolean, default: false},
        showCapabilities: { type:Boolean, default: false},
        showChiefJuges: { type:Boolean, default: false},
        canSetChiefJudges: { type: Boolean, default: false},
        items: { type: Array},
        checkForSelectionCheckboxAccessibility : { type: Boolean, default: false },
    },
    computed:{
        showRoles(){
            return !this.canSelectRole;
        },
        columns(){
            var ret = [];
            if(this.canSelect)
                ret.push({ key: 'selector', sortable: false, label: '', class:"selector", stickyColumn: true});
            if(this.canEdit)
                ret.push({ key:"edit", sortable:false, label: ''});
            if(this.showName || (!this.showFirstname && !this.showLastname && !this.showShortname))
                ret.push({ key: 'name', sortable: true, label: 'Nom', class:"name", stickyColumn: true});
            if(this.showFirstname)
                ret.push({ key: 'firstname', sortable: true, label: 'Prénom', class:"firstname", stickyColumn: true});
            if(this.showLastname)
                ret.push({ key: 'lastname', sortable: true, label: 'Nom', class:"lastname", stickyColumn: true});
            if(this.showShortname)
                ret.push({ key: 'shortname', sortable: true, label: 'Nom court', class:"shortname", stickyColumn: true});
            if(this.showRollnumber)
                ret.push({ key: 'federalNumber', sortable: true, label:'N° licence', class:"federalNumber"});
            if(this.showChiefJuges || this.canSetChiefJudges)
                ret.push({ key: 'chiefJudge', sortable: true, label: 'Chef Juge', class: 'chiefJudge'});
            if(this.showCapabilities || this.canSelectRole)
            {
                if(!this.showTechAndArtForJudge){
                    ret.push({ key:'judge', sortable: false, label: 'Juge', class:"judge"});
                } else {
                    ret.push({ key: 'techJudge', sortable:false, label: 'Jude tech.', class:"judge"});
                    ret.push({ key: 'artJudge', sortable:false, label: 'Jude art.', class:"judge"});
                }
                ret.push({ key:'fieldDirector', sortable: false, label: 'DT', class:"fieldDirector"});
                ret.push({ key:'scorer', sortable: false, label: 'Scoreur', class:"scorer"});
                if(!this.showTechAndArtForJudge){
                    ret.push({ key:'judgeShadow', sortable: false, label: 'Juge ombre', class:"judgeShadow"});
                } else {
                    ret.push({ key: 'techJudgeShadow', sortable:false, label: 'Jude tech. ombre', class:"judgeShadow"});
                    ret.push({ key: 'artJudgeShadow', sortable:false, label: 'Jude art. ombre', class:"judgeShadow"});

                }
                ret.push({ key:'FieldDirectorShadow', sortable: false, label: 'DT ombre', class: "fieldDirectorShadow"});
                ret.push({ key:'scorerShadow', sortable: false, label: 'Scoreur ombre', class: "scorerShadow"})
            }
            if(this.canRemove)
                ret.push({ key:"remove", sortable:false, label: ''});
            return ret;
        },
        itemsList(){
            var ret = [];

            if(this.items !== null)
                ret = this.items.map(element => {
                const isFederal = ((element.federalNumber != null && element.federalNumber != '') || (element.federal_number != null && element.federal_number !=''));
                var licensee = (isFederal || this.showRollnumber) ? { number: element.federalNumber || element.federal_number, isValid: false, isCompetitor: false, isMedicalCertificateValid: false, theme:""} : null;
                if(isFederal){
                    var l = element.LICENSEE;
                    if(l != null){
                        licensee.isValid  = l.isValid;
                        licensee.isCompetitor = l.isCompetitor;
                        licensee.isMedicalCertificateValid = l.isMedicalCertificateValid;
                    }
                    if(!licensee.isValid)
                        licensee.theme="danger"
/*                    else if(!licensee.isCompetitor)
                        licensee.theme="warning"
                    else if(!licensee.isMedicalCertificateValid)
                        licensee.theme="warning";*/
                }

                var complement = {
                        //federalNumber: { number: element.federalNumber, isFederal: element.isFederal, isValid: element.isFederalValid, isActive: element.isFederalActive },
                        federalNumber: licensee,
                        canBeChiefJudge: (element.isSelected && (element.canBeJudge || element.canBeTechJudge || element.canBeArtJudge))
                                        || element.isChiefJudge || element.isJudge || element.isTechJudge || element.isArtJudge,
                  }
/*                    return {
                        id : element.id,
                        isSelected : element.isSelected,
                        cannotBeSelected : element.cannotBeSelected,
                        firstname: element.firstname,
                        lastname: element.lastname,
                        shortname: element.shortname,
                        name: element.name,
                        federalNumber: { number: element.federalNumber, isFederal: element.isFederal, isValid: element.isFederalValid, isActive: element.isFederalActive },
                        canBeJudge: element.canBeJudge,
                        isJudge: element.isJudge,
                        canBeTechJudge: element.canBeTechJudge,
                        isTechJudge: element.isTechJudge,
                        canBeArtJudge: element.canBeArtJudge,
                        isArtJudge: element.isArtJudge,
                        canBeFieldDirector: element.canBeFieldDirector,
                        isFieldDirector: element.isFieldDirector,
                        canBeScorer: element.canBeScorer,
                        isScorer: element.isScorer,
                        canBeJudgeShadow: element.canBeJudgeShadow,
                        isJudgeShadow: element.isJudgeShadow,
                        canBeTechJudgeShadow: element.canBeTechJudgeShadow,
                        isTechJudgeShadow: element.isTechJudgeShadow,
                        canBeArtJudgeShadow: element.canBeArtJudgeShadow,
                        isArtJudgeShadow: element.isArtJudgeShadow,
                        canBeFieldDirectorShadow: element.canBeFieldDirectorShadow,
                        isFieldDirectorShadow: element.isFieldDirectorShadow,
                        canBeScorerShadow: element.canBeScorerShadow,
                        isScorerShadow: element.isScorerShadow,
                    }*/
                    return {...element, ...complement};
                });
                //console.log("detailedStaffList", ret);
            return ret;
        }
    },
    methods:{
        edit(member){
            this.$emit('edit', member.id);
        },
        remove(member){
            this.$emit("remove", member.id);
        },
        add(){
            this.$emit('add');
        },
        selectRole(memberId, roleName){
            this.$emit('roleChanged', { roleName: roleName, memberId: memberId});
        },
        select(member){
            this.$emit('selectionChanged', member, function(){ member.isSelected = !member.isSelected});
        },
        setAsChiefJudge(member){
         this.$emit('chiefJudgeStateChanged', member);
        },
        editLicensee(member){
            this.$emit('editLicensee', member);
        },
    }
}
</script>

<style scoped>
    span.federalNumber.invalid{ color: var(--danger) !important; font-style: italic; }
    span.federalNumber.valid{ color: var(--success) !important; }

    .federalNumber.invalid{ color: var(--danger) !important; font-style: italic;  }
    .federalNumber.danger{ color: var(--danger) !important; font-style: italic;  }
    .federalNumber.warning{ color: var(--warning) !important; font-style: italic; }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        th.federalNumber { font-size: 0.8rem; }
        td.federalNumber { font-size: 0.8rem; }
    }
</style>
