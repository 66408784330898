<template>
    <div>
      <b-row>
        <b-col>
          <b-button @click="add" variant="secondary" v-if="canAdd"><b-icon icon="person-plus-fill" />&nbsp;Ajouter</b-button>
        </b-col>
        <b-col>
          <slot name="headCommandLine"></slot>
        </b-col>
      </b-row>
        <b-table small hover :items="itemsList" :fields="columns" class="thisTable" responsive ref="table" :thead-class="headerClass">
            <!--<template #cell(selector)="data">
                <b-checkbox size="lg" v-model="data.item.isSelected" :disabled="data.item.cannotBeSelected" @change.native="select(data.item)"></b-checkbox>
            </template>-->
            <template #cell(order)="data">
              <b-badge pill variant="info" size="sm" @click="change_order">{{data.item.order}}</b-badge>
            </template>
            <template #cell(name)="data">
                <span class="name" :class="{'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">{{ data.item.name }}</span>
            </template>
            <template #cell(firstname)="data">
                <span class="firstname" :class="{'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">{{ data.item.firstname }}</span>
            </template>
            <template #cell(lastname)="data">
                <span class="lastname" :class="{'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">{{ data.item.lastname }}</span>
            </template>
            <template #cell(shortname)="data">
                <span class="shortname" :class="{'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">{{ data.item.shortname }}</span>
            </template>
            <template #cell(federalNumber)="data">
<!--                <span class="federalNumber" :class="{ 'invalid' : data.item.isFederal && data.item.federalNumber.theme == 'danger', 'warning': data.item.isFederal && data.item.federal_number, 'valid': data.item.isFederal && data.item.federalNumber.theme=='', 'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">
-->
                <span class="federalNumber" :class="{'invalid': data.item.federalNumber.theme=='danger', 'warning': data.item.federalNumber.theme=='warning',  'isWithdrawn': data.item.isWithdrawn, 'isForfeited': data.item.isForfeited, 'isAbsent': data.item.isAbsent}">
                    {{ data.item.federalNumber.number }}
                </span>
                <b-badge v-if="data.item.isFederal && data.item.federalNumber.theme!=''" pill :variant="data.item.federalNumber.theme" @click="editLicensee(data.item)">!</b-badge>
            </template>
            <template #cell(members)="data">
                <div v-html="getMemberListString(data.item)"></div>
            </template>
            <template #cell(editCompetitorCommand)="data">
                <b-button size="sm" variant="secondary" :disabled="!data.item.canEdit" @click="edit(data.item)"><b-icon icon="pencil-fill" /></b-button>
            </template>

            <template #cell(isOpen)="data">
                <b-checkbox switch size="sm" v-model="data.item.isOpen" @change="changeIsOpenStatus(data.item)" :disabled="!data.item.canChangeIsOpenStatus"></b-checkbox>
            </template>


            <template #cell(competitorWithdrawCommand)="data">
                <b-checkbox button size="sm" v-model="data.item.isWithdrawn" :button-variant="data.item.isWithdrawn ? 'secondary' : 'outline-primary'" :disabled="!data.item.canWithdraw" @change="withdraw(data.item)">{{ data.item.isWithdrawn ? 's\'est désisté' : 'est inscrit'}}</b-checkbox>
            </template>
            <template #cell(competitorGiveUpCommand)="data">
                <!--<b-button size="sm" :disbaled="data.item.isAbsent" :variant="data.item.isForfeited ? 'success' : 'warning'" @click="giveUp(data.item)">{{ data.item.isForfeited ? 'Participe' : 'Est forfait' }}</b-button>-->
                <b-checkbox button size="sm" v-model="data.item.isForfeited" :button-variant="data.item.isForfeited ? 'secondary' : 'outline-primary'" :disabled="!data.item.canGiveUp" @change="giveUp(data.item)">{{ data.item.isForfeited ? 'est forfait' : 'participe' }}</b-checkbox>
            </template>
            <template #cell(removeCompetitorCommand)="data">
                <b-button size="sm" variant="warning" :disabled="!data.item.canBeRemoved" @click="remove(data.item)"><b-icon icon="trash-fill" /></b-button>
            </template>
            <template #cell(competitorPresenceChangeCommand)="data">
                <!--<b-button size="sm" :variant="data.item.isAbsent ? 'success' : 'warning'" @click="changePresence(data.item)">{{ data.item.isAbsent ? 'Est présent' : 'Est absent'}}</b-button>-->
                <b-checkbox button size="sm" v-model="data.item.isAbsent" :button-variant="data.item.isAbsent ? 'secondary' : 'outline-primary'" :disabled="!data.item.canChangePresence" @change="changePresence(data.item)">{{ data.item.isAbsent ? 'est absent' : 'est présent' }}</b-checkbox>
            </template>
        </b-table>

        <!--
        <b-dialog v-model="change_competitor_order"
        >
            <p>Veuillez entrer le nouvel ordre de passage prévu pour {{ change_competitor.name }} puis valider.</p>
            <p></p>
        </b-dialog>
        -->
    </div>
</template>

<script>
//import { debounce } from "debounce";

export default {
    props:{
        isFederalContext: {type: Boolean, default: true},
        canAdd: { type: Boolean, default:false},
        canRemove: { type: Boolean, default:false},
        canEdit: { type: Boolean, default:false},
        canSelect: { type: Boolean, default:false},
        canGiveUp: { type: Boolean, default:false},
        canWithdraw: { type: Boolean, default:false},
        canChangePresence: { type: Boolean, default:false},
        canChangeIsOpenStatus: { type:Boolean, default:false},
        canMove: { type:Boolean, default: false},
        showRollnumber: { type: Boolean, default:false},
        showName: { type: Boolean, default: false},
        showFirstname: { type: Boolean, default: false},
        showLastname: { type: Boolean, default: false},
        showShortname: { type: Boolean, default: false},
        showMembers: { type: Boolean, default:false},
        showMembersRollNumber: { type: Boolean, default:false },
        showIsOpen: { type:Boolean, default: false },
        items: { type: Array},
        checkForSelectionCheckboxAccessibility : { type: Boolean, default: false },
        showHeaders: { type: Boolean, default:true },
        showOrder: { type:Boolean, default: false},
        confirmOnDeletion: { type:Boolean, default: true},
    },
    data(){
        return {
            //itemsList: [],
            competitorToDelete: null,
        }
    },
    computed:{
      headerClass(){
        return this.showHeaders ? "" : "d-none";
      },
        columns(){
            var ret = [];
            if(this.canSelect)
                ret.push({ key: 'selector', sortable: false, label: '', class:"selector", stickyColumn: true});
            if(this.showOrder)
              ret.push({ key: 'order', sortable: false, label: 'N°', class:'order', stickyColumn: true});
            if(this.canEdit)
                ret.push({ key:"editCompetitorCommand", sortable:false, label: ''});
            if(this.showName || (!this.showFirstname && !this.showLastname && !this.showShortname))
                ret.push({ key: 'name', sortable: false, label: 'Nom', class:"name", stickyColumn: true});
            if(this.showFirstname)
                ret.push({ key: 'firstname', sortable: false, label: 'Prénom', class:"firstname", stickyColumn: true});
            if(this.showLastname)
                ret.push({ key: 'lastname', sortable: false, label: 'Nom', class:"lastname", stickyColumn: true});
            if(this.showShortname)
                ret.push({ key: 'shortname', sortable: false, label: 'Nom court', class:"shortname", stickyColumn: true});
            if(this.showRollnumber)
                ret.push({ key: 'federalNumber', sortable: false, label: 'N° licence', class:"federalNumber"});
            if(this.showIsOpen)
                ret.push( {key:"isOpen", sortable: false, label:'"Open"', class:"isOpen"});

            if(this.showMembers)
                ret.push({ key:'members', sortable:false, label: 'Composition', class: 'members'});


            if(this.canWithdraw)
                ret.push({ key: "competitorWithdrawCommand", sortable: false, label: ''});
            if(this.canChangePresence)
                ret.push({ key: "competitorPresenceChangeCommand", sortable:false, label: ''});
            if(this.canGiveUp)
                ret.push({ key: "competitorGiveUpCommand", sortable:false, label: ''});
            if(this.canRemove)
                ret.push({ key:"removeCompetitorCommand", sortable:false, label: ''});

            return ret;
        },
        itemsList(){
          var i=1;
            var ret = this.items.map(element => {
                const isFederal = ((element.federalNumber != null && element.federalNumber != '') || (element.federal_number != null && element.federal_number !=''));
                var licensee = (isFederal || this.showRollnumber) ? { number: element.federalNumber || element.federal_number, isValid: false, isCompetitor: false, isMedicalCertificateValid: false, theme:""} : null;
                if(isFederal){
                    var l = element.LICENSEE;
                    if(l != null){
                        licensee.isValid  = l.isValid;
                        licensee.isCompetitor = l.isCompetitor;
                        licensee.isMedicalCertificateValid = l.isMedicalCertificateValid;
                    }
                    if(!licensee.isValid)
                        licensee.theme="danger"
                    else if(!licensee.isCompetitor)
                        licensee.theme="warning"
                    else if(!licensee.isMedicalCertificateValid)
                        licensee.theme="warning";
                }

/*                console.log('check members ?', this.isFederalContext, this.showMembersRollNumber, element.members);
                if(this.isFederalContext && this.showMembersRollnumber && element.members && element.members.length > 0){
                    element.members = element.members.map(member => {
                        const memberIsFederal = this.isValidLicenseeNumber(member.federal_number);
                        console.log('member', memberIsFederal, member )
                        if(!memberIsFederal)
                            return { ...member, theme: "danger" };
                        const memberLICENSEE = member.LICENSEE;
                        if(memberLICENSEE && memberLICENSEE != null){
                            if(!memberLICENSEE.isValid)
                                return { ...member, theme: "danger" };
                            if(!memberLICENSEE.isCompetitor)
                                return { ...member, theme: "warning"};
                            if(!memberLICENSEE.isMedicalCertificateValid)
                                return { ...member, theme: "warning"};
                            return { ...member, theme: ""};
                        } else
                            return { ...member, theme:"danger" };
                    });
                }*/
              var complement = {
                order: i++,
                isFederal: isFederal,
                firstname: (element.COMPETITOR) ? (element.COMPETITOR.isIndividual ? element.COMPETITOR.PEOPLE.firstname : element.COMPETITOR.name) : element.firstname,
                lastname: (element.COMPETITOR) ? (element.COMPETITOR.isIndividual ? element.COMPETITOR.PEOPLE.lastname : element.COMPETITOR.name) : element.lastname,
                shortname: (element.COMPETITOR) ? element.COMPETITOR.shortname : element.shortname,
                name: (element.COMPETITOR) ? element.COMPETITOR.name : element.name,
                federalNumber: licensee, /*{ 
                    number: (element.COMPETITOR) 
                            ? (element.COMPETITOR.isIndividual ? element.COMPETITOR.PEOPLE.federal_number : "-")
                            : element.federal_number, 
                    isFederal: element.isFederal, 
                    isValid: element.isFederalValid,
                },*/
                canEditComposition: false,
              };
/*                    return {
                        order: i++,
                        id : element.id,
                        isSelected : element.isSelected,
                        cannotBeSelected : element.cannotBeSelected,
                        firstname: (element.COMPETITOR) ? (element.COMPETITOR.isIndividual ? element.COMPETITOR.PEOPLE.firstname : element.COMPETITOR.name) : element.firstname,
                        lastname: (element.COMPETITOR) ? (element.COMPETITOR.isIndividual ? element.COMPETITOR.PEOPLE.lastname : element.COMPETITOR.name) : element.lastname,
                        shortname: (element.COMPETITOR) ? element.COMPETITOR.shortname : element.shortname,
                        name: (element.COMPETITOR) ? element.COMPETITOR.name : element.name,
                        members: element.members,
                        isWithdrawn: element.isWithdrawn,
                        isForfeited: element.isForfeited,
                        isAbsent: element.isAbsent,
                        federalNumber: { number: (element.COMPETITOR) ? (element.COMPETITOR.isIndividual ? element.COMPETITOR.PEOPLE.federal_number : "-") : element.federal_number, isFederal: element.isFederal, isValid: element.isFederalValid },
                        canEditComposition: false,
                        canGiveUp: element.canGiveUp,
                        canWithdraw: element.canWithdraw,
                        canChangePresence: element.canChangePresence,
                        canBeRemoved: element.canBeRemoved,
                        canEdit: element.canEdit,
                    }
                        */
                return { ...element, ...complement };
            });
            return ret;

        }
    },
    methods:{
        edit(competitor){
            this.$emit('edit', competitor);
        },
        editLicensee(competitor){
            this.$emit('editLicensee', competitor);
        },
        order_click(competitor){
            if(this.canMove){
                console.log('order_click', { competitor: competitor})
//                if()
            }
        },
        async remove(competitor){
          if(this.confirmOnDeletion){
            try{
              var value = await this.$bvModal.msgBoxConfirm('Vous demandez la suppression de ' + competitor.name + ' de la liste des compétiteurs inscrits à cette compétition. Cette action est irréversible. Confirmez-vous ce choix ?', {
                title: 'Confirmation de suppression',
                size: 'lg',
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Oui",
                cancelTitle: "Annuler",
                cancelVariant: "secondary",
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
              });
              if(value){
                await this.$showSaver();
                this.competitorToDelete = competitor;
                setTimeout(this.emitRemove, 50);
               }
            }
            catch(err) {
              console.log("remove competitor from list ERROR =>", err);
            }
          } else {
              this.$emit('remove', competitor);
          }
        },
        emitRemove(){
          console.log('emiting remove event');
          this.$emit('remove', this.competitorToDelete);
          this.competitorToDelete = null;
        },
        add(){
            this.$emit('add');
        },
        changeIsOpenStatus(competitor){
            this.$emit("isOpenStatus_change", competitor);
        },
        giveUp(competitor){
          this.$emit('forfeitState_change', competitor);
        },
        changePresence(competitor){
            this.$emit('presenceState_change', competitor);
        },
        withdraw(competitor){
            this.$emit('withdrawState_change', competitor);
        },
        select(competitor){
            this.$emit('selectionChanged', competitor);
        },
        moveUp(competitor){
            this.$emit('move_up', competitor);
        },
        moveDown(competitor){
            this.$emit('move_down', competitor);
        },
        getMemberListString(competitor){
            var ret = '';
            if(competitor.members != null && competitor.members.length){
                var separator = '';
                competitor.members.forEach(member => {
                    var s = ''; //separator;
                    if(this.showName || (!this.showFirstname && !this.showLastname && !this.showShortname))
                        s = s + member.firstname + ' ' + member.lastname;
                    else if(this.showFirstname)
                        s = s + ' ' + member.firstname;
                    else if(this.showLastname)
                        s = s + member.lastname;
                    else if(this.showShortname)
                        s = s + member.shortname;

                    if(this.showMembersRollnumber && member.federal_number != null && member.federal_number != '')
                        s = s + ' (' + member.federal_number + ')';

                    var cssClass = '';
                    if(member.isFederal)
                        cssClass = member.isFederalValid ? 'valid' : 'invalid';

                    var theme = "";
                    if(this.isFederalContext && this.showMembersRollNumber)
                    {
                        if(!this.isValidLicenseeNumber(member.federalNumber))
                            theme = "big-danger";
                        else if(!member.LICENSEE)
                            theme = "danger";
                        else if(!member.LICENSEE.isValid)
                            theme = "danger";
                        else if(!member.LICENSEE.isCompetitor)
                            theme = "warning";
                        else if(!member.LICENSEE.isMedicalCertificateValid)
                            theme = "warning";
                    }
                    cssClass += ' ' + theme;
                    ret = ret + separator + '<span class="member ' + cssClass + '">' + s + '</span>';
                    //ret += s;
                    separator = ', ';
                });
            }
            return ret;
        },
    },/*
    watch:{
      competitorToDelete: function(newValue){
        if(newValue)
          this.emitRemove();
      }
    }*/

}
</script>

<style >
    span.member{ background-color: rgb(232, 243, 254); /*border: solid 1px #b7b7b7;*/ white-space: nowrap; }
    span.member.big-danger { color: var(--danger) !important;   }
    span.member.danger { color: var(--warning) !important;  }
    span.member.warning { color: var(--warning) !important; }

</style>
<style scoped>
 
    .federalNumber.invalid{ color: var(--danger) !important; font-style: italic;  }
    .federalNumber.danger{ color: var(--danger) !important; font-style: italic;  }
    .federalNumber.warning{ color: var(--warning) !important; font-style: italic; }
    .isForfeited,.isAbsent,.isWithdrawn { text-decoration: line-through; color: #b7b7b7;}
    @media screen and (max-width: 767px) and (orientation: portrait) {
        th.federalNumber { font-size: 0.8rem; }
        td.federalNumber { font-size: 0.8rem; }
    }

</style>
